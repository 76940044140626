const messages = {
  general: {
    showImage: "FULLSCREEN",
    id: "Id",
    uid: "UID",
    resourceUpdated: "%{resource} updated",
    resourceCreated: "%{resource} created",
    successClipboard: "Successfully copied to clipboard.",
    markAsPreview: "MARK AS PREVIEW",
    markedAsPreview: "PREVIEW IMAGE",
    created: "Created",
    updated: "Updated",
    previewPhoto: "Preview photo",
    title: "Title",
    body: "Message",
    notificationType: "Notification type",
    advId: "Advertisement ID",
    price: "Price",
    rooms: "Rooms",
    occupancy: "Occupancy",
    availableFrom: "Available from",
    advertisementType: "Advertisement type",
    url: "URL",
  },
  video: {
    listTitle: "Videos",
    dialog: {
      editTitle: 'Update "How to video"',
      createTitle: "Create video",
    },
    field: {
      type: "Type",
      url: "URL",
      preview: "Preview",
    },
  },
  advertisement: {
    plot: "Plot",
    area: "Area",
    firstName: "Firstname",
    lastName: "Lastname",
    others: "Others",
    streetName: "Street",
    number: "Number",
    advId: "Advertisement id",
    pricing: "Pricing",
    pricingType: 'Please select pricing type',
    monthly: "Monthly",
    months: "months",
    media: "Media",
    latitude: "Latitude",
    detailUrl: "Detail url",
    longitude: "Longitude",
    address: "Address",
    rejectedReasons: "Rejected reasons",
    offers: "Offers",
    coordinates: "Coordinates",
    rent: "Rent",
    details: "Details",
    advertisement: "Advertisement",
    type: "Type",
    price: "Price",
    description: "Description",
    rating: "Rating",
    globalRate: "Overall rating",
    occupancy: "Occupancy",
    rooms: "Rooms",
    city: "City",
    advertisementType: "Advertisement type",
    owner: "Owner",
    fakeUser: "Fake user",
    monthlyPayment: "Monthly payment",
    deposit: "Deposit",
    streetNumber: "Street number",
    country: "Country",
    appartmentNumber: "Appartment number",
    additionalNumber: "Additional number",
    bathroomTypes: "Bathroom type",
    totalRooms: "Total rooms",
    selectedAmenities: "Selected ammenities: %{count}",
    url3d: "3D url",
    state: "State",
    numOfBathrooms: "Number of bathrooms",
    detailId: "Detail ID",
    broker: "Broker",
    amenities: "Amenities",
    availableFrom: "Available from",
    minimalRent: "Minimal rent time",
    floor: "Floor No.",
    floors: "Floors",
    maximalOccupancy: "Maximal occupancy",
    floorage: "Floorage",
    petsAllowed: "Pets permission",
    auctionUrl: "Auction url",
    priceType: "Price type",
    dynamicLinkTitle: "Pozri si túto nehnuteľnosť.",
    listTitle: "Advertisements",
    editTitle: "Edit advertisement",
    showTitle: "Advertisement",
    createTitle: "Create advertisement",
    video: "Video",
    youtubeUrl: "Youtube url",
    externalAdvertisementListTitle: "External advertisement",
    petsAllowedState: "allowed",
    petsDisallowedState: "disallowed",
    occupants: "Occupants",
    hostedBy: "This %{advertisementType} is hosted by",
    priceRent: "Price per month"
  },
  office: {
    listTitle: "Offices",
    createTitle: "Create office",
    editTitle: "Edit office",
    showTitle: "Office detail ",
    logo: "Logo",
    brokers: "Brokers",
    general: "General",
    name: "Name",
    address: "Address",
    longitude: "Longitude",
    latitude: "Latitude",
    addBrokerButton: "Add broker",
    removeBrokerButton: "Remove",
    upload: "Upload",
    searchBroker: "Search broker",
    coordinates: "Location",
    show: {
      title: "Office",
    },
  },
  user: {
    types: {
      LOOKING_RENT: "Tenant",
      PROPERTY_OWNER: "LandLord",
      REAL_ESTATE_AGENT: "Real Estate Agent",
    },
    roles: {
      ROLE_USER_BROKER: "BROKER",
      ROLE_USER: "USER",
      ROLE_BO_ADMIN: "ADMIN",
    },
    listTitle: "Users",
    id: "ID",
    uid: "UID",
    age: "Age",
    gender: "Gender",
    createTitle: "Create user",
    profilePicture: "Profile picture",
    editTitle: "Edit user",
    role: "Role",
    state: "State",
    resetPassword: "Reset password",
    provider: "Provider",
    exportToRealSoft: "Export To RealSoft",
    emailVerified: "Email Verified",
    emailNotVerified: "Email Not verified",
    password: "Password",
    disabled: "Disabled",
    enabled: 'Enabled',
    email: "E-mail",
    type: "Type",
    phone: "Phone",
    phoneVerified: "Phone verified",
    office: "Office",
    firstName: "Name",
    lastName: "Surname",
    photo: "Photo",
    createdAt: "Created at",
    lastLogged: "Last logged at",
    device: "Device",
    cities: "Cities",
    services: "Services",
    detail: {
      description: "Description",
      video: "Video",
      cities: "Cities",
      office: "Office",
    },
    button: {
      deactivate: "Deactivate",
      activate: "Activate",
      upgradeToBroker: "Upgrade",
    },
    dialog: {
      dialog: {
        title: "User detail",
      },
      detail: {
        title: "User detail",
      },
      upgrade: {
        title: "Upgrade to Broker",
        submitButton: "Upgrade",
      },
      activate: {
        success: "User was activated",
      },
      deactivate: {
        success: "User was deactivated",
      },
      message: {
        successUpgrade: "Upgrade to broker was successful",
      },
    },
  },
  pushNotification: {
    occupancy: "Occupancy",
    advertisementType: "Advertisement type",
    availableFrom: "Available from",
    dashboardOpen: "Dashboard open",
    title: "Title",
    body: "Body",
    rooms: "Rooms",
    price: "Price",
    url: "Url",
    advId: "Advertisement ID",
    dialog: {
      title: "Do you want to send push notification?",
      subtitle:
        "This action will send push notification to all selected users.",
    },
    sendPushNotification: "Send push notification",
    sendSuccess: "Push messages successful sended",
    types: {
      PROFILE_OPEN: "Profile open",
      DASHBOARD_OPEN: "Dashboard open",
      MAP_OPEN: "Map open",
      IMPORTANT_OPEN: "Important open",
      ADV_OPEN: "Advertisement open",
      ADV_EDIT: "Advertisement edit",
      DASHBOARD_FILTERED: "Dashboard filtered",
      MAP_FILTERED: "Map filtered",
      EXTERNAL_OPEN_URL: "External open url",
    },
  },
  dropzone: {
    text: "Drag 'n' drop some files here, or click to select files",
    error: "Photos are required"
  },
  coordinates: {
    error: 'Coordinates are requried'
  },
  success: {
    general: "The action was successful",
  },
  validation: {
    medias: "Media is missing",
    advertisementType: "Advertisement type is missing",
    address: {
      city: "City is missing",
      number: "Street number is missing",
      streetName: "Street name is missing",
      country: "Country is missing",
    },
    pricing: {
      price_total: "Price is missing",
    },
    details: {
      roomsInTotal: "Total rooms is missing",
      floor: "Floor is missing",
      numberOfBathrooms: "Number of bathrooms is missing",
      area: "Area is missing",
      plot: "Plot is missing",
    },
  },
  error: {
    failedFetch: "Failed to fetch",
    unexpected: "Sorry, an unexpected error occurred",
    firebase: {
      notFound: "OBJECT NOT FOUND",
    },
  },
};

export default messages;
