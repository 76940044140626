import { Box, Typography, Paper, styled, Grid } from "@mui/material";
import { useRecordContext, BooleanField, useTranslate, DateField } from "react-admin";
import SectionTitle from "../../components/common/SectionTitle";
import PersonInfo from "../../components/common/PersonInfo";
import { User } from "../../types/model/model";
import React from "react";
import Checkbox from "@mui/material/Checkbox";

const CardPaper = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  overflow: "hidden",
}));

const List = styled("ul")(({ theme }) => ({
  padding: 0,
  "& > li": {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 4,
    paddingBottom: 4,
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.grey[300],
    borderBottomWidth: 1,
  },
}));

const CitiesField = () => {
  const record = useRecordContext();
  return (record.cities && record.cities.length > 0) ? (
    <p style={{ margin: "0" }}>
      {record.cities.map((item, index) => (
        <React.Fragment key={item}>
          {index > 0 && ", "}
          <span>{item}</span>
        </React.Fragment>
      ))}
    </p>
  ) : null;
};

const ServicesField = () => {
  const record = useRecordContext();
  return (record.services && record.services.length > 0) ? (
    <p style={{ margin: "0" }}>
      {record.services.map((item, index) => (
        <React.Fragment key={item}>
          {index > 0 && ", "}
          <span>{item.value}</span>
        </React.Fragment>
      ))}
    </p>
  ) : null;
};


const UserDetail = (props) => {
  const record = useRecordContext<User>(props);
  const t = useTranslate();
  if (!record) return null;

  return (
    <Box p={4}>
      <Box
        display="flex"
        p={4}
        paddingLeft={0}
        paddingRight={0}
        sx={{ maxWidth: "50rem" }}
      >
        <Box flex="2">
          <Typography variant="h1">{`${record?.firstName || ""} ${
            record?.lastName || ""
          }`}</Typography>
          <Typography variant="h2">{record?.office?.name}</Typography>
        </Box>
        <Box flex="1" pl={2}>
          <Box
            pb={2}
            textAlign="right"
            sx={{ display: "flex", justifyContent: "flex-end", gap: "1rem" }}
          >
            <Typography sx={{ textTransform: "uppercase" }}>{`${t(
              "user.emailVerified"
            )}:`}</Typography>
            <BooleanField
              label={"user.emailVerified"}
              source="emailVerified"
              sx={{
                color: record.emailVerified === true ? "#00E400" : "#000000de",
              }}
            />
          </Box>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item sm={12} md={8}>
          <CardPaper elevation={4} sx={{ maxWidth: "50rem" }}>
            <PersonInfo
              photo={record.photo}
              firstName={record.firstName}
              lastName={record.lastName}
              website={record.webUrl}
              bookMeUrl={record.bookMeUrl}
              role={record.role}
            />
            <Box p={4}>
              <SectionTitle>{t("user.dialog.dialog.title")}</SectionTitle>
              <List>
                <li>
                  <Typography>{t("user.id")}</Typography>
                  <Typography>{record?.originalId}</Typography>
                </li>
                <li>
                  <Typography>{t("user.uid")}</Typography>
                  <Typography>{record?.uid}</Typography>
                </li>
                <li>
                  <Typography>{t("user.email")}</Typography>
                  <Typography>{record?.email}</Typography>
                </li>
                <li>
                  <Typography>{t("user.age")}</Typography>
                  <Typography>{record?.age}</Typography>
                </li>
                <li>
                  <Typography>{t("user.gender")}</Typography>
                  <Typography>{record?.gender}</Typography>
                </li>
                <li>
                  <Typography>{t("user.office")}</Typography>
                  <Typography>{record?.office?.name}</Typography>
                </li>
                <li>
                  <Typography>{t("user.phone")}</Typography>
                  <Typography>{record?.phone}</Typography>
                </li>
                <li>
                  <Typography>{t("user.phoneVerified")}</Typography>
                  <Typography>{record?.phoneVerified}</Typography>
                </li>

                <li>
                  <Typography>{t("user.exportToRealSoft")}</Typography>
                  <Checkbox  checked={record?.exportToRealSoft} />
                </li>
                <li>
                  <Typography>{t("user.provider")}</Typography>
                  <Typography>{record?.provider}</Typography>
                </li>
                <li>
                  <Typography>{t("user.createdAt")}</Typography>
                  <DateField
                    label="user.createdAt"
                    source="createdAt"
                    options={{
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                    }}
                  />
                </li>
                <li>
                  <Typography>{t("user.lastLogged")}</Typography>
                  <DateField
                    label="user.lastLogged"
                    source="lastLoggedAt"
                    options={{
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                    }}
                  />
                </li>
                <li>
                  <Typography>{t("user.device")}</Typography>
                  <Typography>{record?.device}</Typography>
                </li>
                <li>
                  <Typography>{t("user.cities")}</Typography>
                  <CitiesField/>
                </li>
                <li>
                  <Typography>{t("user.services")}</Typography>
                  <ServicesField/>
                </li>

              </List>
            </Box>
          </CardPaper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserDetail;
